import logo from "./logo.png";
import smallLogo from "./logo.ico";
import logoCop from "./logo-cop16.png";
import "./App.css";

function App() {
  return (
    <>
      <nav className="navbar">
        <a href="/">
          <img src={smallLogo} className="navbar-logo" alt="Logo de Umuka" />
        </a>
        <li>
          <a
            href="https://www.instagram.com/umuka.co/"
            className="nav-link"
            target="_blank"
          >
            Instagram
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=573174526832&text=¡Hola!%20Los%20vi%20en%20la%20COP16."
            className="nav-link"
            target="_blank"
          >
            WhatsApp
          </a>
        </li>
      </nav>
      <header className="app-header">
        <h1 className="welcome-text">Estamos en la COP16 🍃</h1>
        <p className="description-text">
          Visita nuestro stand y compartamos juntos lindas experiencias
          papeleras
        </p>

        <div className="logos-container">
          <img src={logo} className="app-logo" alt="Logo de Umuka" />
          <div className="logos-divider"></div>
          <img src={logoCop} className="app-logo" alt="Logo de COP16" />
        </div>
      </header>
      <main className="app-content">
        <p className="content-text">
          ¡Comparte en nuestras redes sociales tu fotos 📸 y vídeos 📹, para
          tener recuerdos juntos 🤳!
        </p>
      </main>
      <footer className="app-footer">
        <p className="footer-text">
          &copy; {new Date().getFullYear()} Umuka. All rights reserved.
        </p>
      </footer>
    </>
  );
}

export default App;
